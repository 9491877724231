import { getMultipleQueryValue } from '@/util';

let SORT_ARGS = {
  ORDERING: 'ordering'
};

export default class TaskAppSortModel {
  constructor(query) {
    this.by = [];
    this.desc = [];
    this.ordering = null;
    this._apiAttributes = {
      name: 'name',
      count: 'count',
      amount: 'amount',
      count_unpaid: 'count_unpaid',
      amount_unpaid: 'amount_unpaid',
      course_type: 'course_type',
      is_paid: 'is_paid',
      created_at: 'created_at',
      driving_school__name: 'driving_school__name',
      calculated: 'calculated'
    };
    this._customSorting = {};
    this._orderingFields = {
      name: 'name',
      count: 'count',
      amount: 'amount',
      count_unpaid: 'count_unpaid',
      amount_unpaid: 'amount_unpaid',
      course_type: 'course_type',
      is_paid: 'is_paid',
      created_at: 'created_at',
      driving_school__name: 'driving_school__name',
      calculated: 'calculated'
    };
    this._initByQuery(query);
  }

  _initByQuery(query) {
    for (let sortField in this._apiAttributes) {
      if (this._apiAttributes[sortField] in query) {
        this.by.push(sortField);
        this.desc.push(query[this._apiAttributes[sortField]]);
      }
    }
    if (query[SORT_ARGS.ORDERING]) this._initOrdering(query[SORT_ARGS.ORDERING]);
    this._serializeQueryBoolean();
  }

  _initOrdering(query) {
    let val = getMultipleQueryValue(query);
    val.forEach(field => {
      let orderingName = field.replace('-', '');
      if (!(orderingName in this._orderingFields)) return;

      let fieldVal = field === '-' ? fieldVal = true : fieldVal = false;
      this.by.push(this._orderingFields[orderingName]);
      this.desc.push(fieldVal);
    });
  }

  _serializeQueryBoolean() {
    if (!this.desc.length) return;
    this.desc = this.desc.map(value => {
      if (value === 'false') value = false;
      if (value === 'true') value = true;
      return value;
    });
  }

  getApiData(query) {
    let data = {};
    this._setOrdering(data, query);
    return data;
  }

  _getQueueApiData() {
    let index = this.by.findIndex(field => field === 'inQueue');
    if (index < 0) return;
    return this._transformQueryBoolean(this.desc[index]);
  }

  _transformQueryBoolean(val) {
    if (val === 'false') return false;
    if (val === 'true') return true;
    return val;
  }

  _getFailedApiData() {
    let index = this.by.findIndex(field => field === 'failed');
    if (index < 0) return;
    return this._transformQueryBoolean(this.desc[index]);
  }

  _getOnHoldApiData() {
    let index = this.by.findIndex(field => field === 'onHold');
    if (index < 0) return;
    return this._transformQueryBoolean(this.desc[index]);
  }

  _getOrderingApiData() {
    let index = this.by.findIndex(field => field === 'ordering');
    if (index < 0) return;
    return this._transformQueryBoolean(this.desc[index]);
  }

  _setOrdering(data) {
    let ordering = [];
    this.by.forEach(field => {
      if (!(field in this._customSorting)) { // if false - set ordering
        let index = this.by.findIndex(selectedField => selectedField === field);
        ordering.push(this._convertBooleanToOrderingFormat(this.desc[index], this._apiAttributes[field]));
      } else if (field in this._customSorting) this._customSorting[field](ordering);
    });
    data[SORT_ARGS.ORDERING] = ordering;
  }

  _removeSelectedOrdering(field, data) {
    if (SORT_ARGS.ORDERING in data && (data[SORT_ARGS.ORDERING] !== field || data[SORT_ARGS.ORDERING] !== `-${ field }`)) {
      let fieldName = SORT_ARGS.ORDERING.replace('-', '');
      let fieldNameToRemove = this._orderingFields[fieldName];
      let index = this.by[fieldNameToRemove];
      this.by.splice(index, 1);
      this.desc.splice(index, 1);
    }
  }

  _convertBooleanToOrderingFormat(val, field) {
    if (!val) return `-${ field }`;
    if (val) return field;
    return;
  }

  _convertBooleanToMultiOrderingFormat(val, field) {
    if (!val) return field.split(',').map(item => `-${ item }`).join(',');
    if (val) return field.split(',').map(item => `${ item }`).join(',');
    return;
  }
}
